import React from "react";
import { AGECard, AgeWorkWrapper, MainHeading } from "./elements";
import { artwork, tokenicon } from "assets";

const AgeWork = () => {
  let data = [
    {
      title: "Art Sharing Rewards",
      info: [
        {
          data: "The more you interact, the more points you earn. This  includes sharing art, liking, and commenting.",
        },
        {
          data: "Points accumulate automatically based on the quality and  quantity of interactions.",
        },
      ],
      icon: artwork,
    },
    {
      title: "️️⭐ Points Leaderboard",
      info: [
        {
          data: "Steadily accumulate points through interactions with tweets tagged #age.",
        },
        {
          data: "Our system updates your total points in real-time.",
        },
      ],
    },
    {
      title: "🔍 AGE Points Calculator",
      info: [
        {
          data: "Predict your points earnings by inputting interaction data.",
        },
        {
          data: "Get points suggestions for different types of interactions.",
        },
      ],
    },
    {
      title: "💎 Token Exchange",
      info: [
        {
          data: "After the activity cycle ends, you will automatically receive $AGE token airdrops as rewards based on your points.",
        },
        {
          data: "Token conversion rates are adjusted periodically based on market value.",
        },
      ],
    },
  ];

  return (
    <AgeWorkWrapper>
      <MainHeading>How $AGE Works?</MainHeading>
      <div className="mt-5 ageCardWrapper">
        {data?.map((item, index) => {
          return (
            <AGECard key={index}>
              <p className="title">
                {" "}
                {item.icon && <img src={item.icon} alt="" />} {item.title}
              </p>
              <ul>
                {item.info.map((item, index) => (
                  <li key={index}>{item.data}</li>
                ))}
              </ul>
            </AGECard>
          );
        })}
      </div>
    </AgeWorkWrapper>
  );
};

export default AgeWork;
