import React from "react";
import { FooterWrapper, Text } from "./elements";
import { Container } from "react-bootstrap";
const FooterComp = () => {
  return (
    <FooterWrapper>
      <Container>
        <div className="mainWrapper">
          {/* First Column */}
          <div>
            <Text>AGE Coin</Text>
            <Text
             style={{ marginTop: "2.4rem" }}
            >
             AGE Coin: ArtFi Revolution on Twitter/X
            </Text>
          </div>

          {/* Second Column */}
          <div className="">
            <Text>Media</Text>

            <ul
              style={{
                listStyle: "none",
                paddingLeft: 0,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                textAlign: "start",
                color: "white",
                fontSize: "16px",
                gap: "0.6rem",
                marginTop: "2rem",
                fontWeight: "400",
              }}
            >
              <li>
                <a href="https://twitter.com/artGee15" target="_blank">
                  Twitter(X)
                </a>{" "}
              </li>
              <li>
                <a href="https://t.me/agecoinofficial" target="_blank">
                  Telegram
                </a>{" "}
              </li>
              <li>
                <a href="https://artgee6.medium.com/" target="_blank">
                  Medium
                </a>{" "}
              </li>
              <li>
                <a href="https://www.instagram.com/artgee15/" target="_blank">
                  Instagram
                </a>{" "}
              </li>
            </ul>
          </div>

          {/* Third Column */}
          <div>
            <Text>
              Email
              <p style={{ marginTop: "2.4rem" }} className="email" >hi@artgee.io</p>
            </Text>
          </div>
        </div>
      </Container>
    </FooterWrapper>
  );
};

export default FooterComp;
