import styled from "styled-components";
import bgImg from "assets/images/bg.png";
import ReactSlider from "react-slider";

export const HeroSectionWrapper = styled.div`
  background: #2037b6;

  .main-card-div {
    display: flex;
    justify-content: end;
  }
`;

export const MainSection = styled.section`
  .main-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .idoText {
    color: #fff;
    font-size: 50px;
    font-style: normal;
    font-family: Montserrat;
    margin-top:-0.5rem;

    font-weight:700;
    line-height: 70.64px;
    // text-transform: capitalize;
    text-align: start;

    @media (max-width: 1400px) {
      font-size: 40px;
      margin-top: 0rem;
    }

   
    @media (max-width: 990px) {
      font-size: 40px;
      text-align: center;
      margin-top: 0rem;
    }
  }

  .main-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-bottom: 6rem;
    padding-top: 1rem;

    .textDescription {
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 700;
      line-height: 29.26px;
      text-align: center;

      color: white;
      margin: 0;
      padding: 0;
      margin: 0.9rem 0;
    }

    @media (max-width: 990px) {
      .launchDiv {
        display: flex;
        justify-content: center;
      }
    }
  }
`;

export const CoinWrapper = styled.span`
  display: inline-block;
  span {
    position: relative;
    z-index: 3;
    color: white !important;
  }
`;

export const Rectangle = styled.div`
  width: 240px;
  height: 20px;
  background-color: #bd4088;
  position: absolute;
  margin-top: -1.25rem;
  z-index: 2;

  @media (max-width: 1400px) {
    width:193px;
    height:17px;
    margin-top: -1.3rem;
  
    margin-left: 0rem;
  }

  @media (max-width: 1200px) {
    // width: 180px;
    // height: 27px;
    // margin-left: 0.2rem;
  }
  @media (max-width: 467px) {
    display: none;
  }
`;

export const Title = styled.h5`
  color: #fff;
  font-size: 50px;
  font-style: normal;
  font-family: Montserrat;

  font-weight: 700;
  line-height: 42.64px;

  span {
    color: #ffa701;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 42.64px;
    text-transform: capitalize;

    @media (max-width: 767px) {
      font-size: 24px;
      text-align: center;
    }
  }

  @media (max-width: 1400px) {
    font-size: 40px;
    span {
      font-size: 40px;
    }
  }
  @media (max-width: 1200px) {
    font-size: 40px;
    span {
      // font-size: 40px;
    }
  }
  @media (max-width: 990px) {
    // font-size: 24px;
    text-align: center;
    line-height: 3rem;
  }
`;

export const CardDiv = styled.div`
padding: 25px 20px;

width: 451px;
height: 480px;


border: 2px solid white;
border-radius: 0px;
color: white;
font-size: 14px;

margin: 20px 0;
z-index:2;


@media (max-width: 567px) {
  width:100%
}
.purchaseBtn
 {
  background:#fff;
  color:#2037B6;
 
font-size: 16px;
font-weight: 600;
display:flex;
justify-content:center;
align-items:center;
padding:0.6rem;
gap:0.5rem;
border:none;
margin-top:2rem;


:hover
{
  background:#2037B6;
  color:white;
  // box-shadow: 0px 4px 4px 0px #00000040;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}

 }

  .input-wrapper {
    position: relative;
    margin: 10px;
  }

  .input-field {
    border: 1px solid #d5dfd5;
    border-radius: 0px;
    background-color: transparent;
    width: 200px;
    color: white;
    z-index: 0;
    padding: 5px;
  }
  .input-field:focus {
    // border: 1px solid #d5dfd5;

    outline: none;
    box-shadow: none;
  }

  margin-top: 4rem;
  
  
 .cardWrapper
 {

   width: 100%;
  // width:400px;
 
  margin-bottom: 2rem !important;
  
  .logoDiv
  {
    display:flex;
    justify-content:center;
    margin:1rem 0;
  }
  
.estimatedPara
{
// font-family: Inter;
font-size: 13.84px;
font-weight: 400;
line-height: 16.75px;
margin-top:0.4rem;
font-weight: 600;

}

.estimatedValue
{
// font-family: Inter;
font-size: 13.84px;
font-weight: 600;
line-height: 16.75px;
text-align: left;
margin-top:0.4rem;


}
  
.box {
  --border-width: 2px;
  
  background: rgba(178, 171, 171, 0.1);
  backdrop-filter: blur(16.399999618530273px);


  width: 100%;
  min-height: 349px;

  border: solid var(--border-width) transparent;
  
  // height: 100px;
  // width: 100px;
}

#myBox {
  
  // background: rgba(178, 171, 171, 0.1);
  // backdrop-filter: blur(16.399999618530273px);

  --angle: 0deg;

  border-image: conic-gradient(from var(--angle), black, cyan) 1;
  animation: 4s rotate-border linear infinite;
  

     
  background-size: cover;
  background-position: 0px;

}

#myBox2 {
  --angle: 0deg;
  --duration: 8s;
  --glow: 12px;
  --blur: 16px; /* Added blur variable */
  border-radius: 15px;
  background: 
  linear-gradient(#201f1f, #201f1f),
  conic-gradient(from var(--angle), black 0%, #F0A500 95%, black 100%),
  url(${bgImg}) no-repeat;
  background-size: cover;

 
    
  background-origin: border-box;
  background-clip: content-box, border-box;
   animation: var(--duration) rotate-border linear infinite;
  
  position: relative;
}

#myBox2:after {
  --angle: 0deg;
  background-clip: content-box, border-box;
  background-image: linear-gradient(black, black),
    // conic-gradient(
    //   from var(--angle),
    //   transparent 92%,
    //   #F0A500 95%,
    //   transparent 98%
    // );
    // border: 1.45px solid;
    

 

  background-origin: border-box;
  border: solid var(--glow) transparent;
  border-radius: 15px;
  content: "";
  height: calc(100% - 2 * var(--border-width));
  width: calc(100% - 2 * var(--border-width));
  top: calc(-1 * var(--glow) + var(--border-width));
  left: calc(-1 * var(--glow) + var(--border-width));
  animation: var(--duration) rotate-border linear infinite;
  position: absolute;
  z-index: -2;
}
#myBox2:before {
  box-shadow: inset 0 0 10px 10px var(--bg-color);
  content: "";
  height: calc(100% + 2 * var(--glow));
  width: calc(100% + 2 * var(--glow));
  position: absolute;
  top: calc(-1 * var(--glow));
  left: calc(-1 * var(--glow));
}

@keyframes rotate-border {
  to {
    --angle: 360deg;
    --rotation-angle: 360deg;
  }
}

@property --angle {
  syntax: "<angle>";  
  initial-value: 0deg;
  inherits: false;
}

@property --rotation-angle {
  syntax: "<angle>";  
  initial-value: 0deg;
  inherits: false;
}

 
  
  .info-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.3rem;
    h5 {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 21.627px; /* 135.169% */
      margin-bottom: 0rem;

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }

    p {
      color: #f3f3f3;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 21.627px; /* 135.169% */
      margin-bottom: 0rem;

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
`;

export const Name = styled.h5`
  color: #ffa701;
  font-size: 25.646px;
  font-style: normal;
  font-weight: 600;
  line-height: 38.424px; /* 149.823% */
  text-transform: capitalize;
  text-align: center;
  margin-top: 2rem;
`;

export const MainWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 2rem;
  padding-bottom: 2rem;
`;

export const StartButton = styled.button`
  color: #f0a500;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 5px;
  border: 1px solid #f0a500;
  width: 140px;
  height: 39px;
  display: flex;
  justify-content: center;
  item-align: center;
  align-items: center;
  gap: 0.5rem;
  background-color: transparent;

  @media (max-width: 990px) {
    margin-top: 1rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &:hover {
    border-radius: 5px;
    border: 0.989px solid #f0a500;
    background: #f0a500;
    color: #fff;
  }
`;

// AgeNetworkInfoWrapper
export const AgeNetworkInfoWrapper = styled.div`
  background: #fdfbef;
  padding-top: 5.5rem;
  padding-bottom: 4rem;
  .text_dis {
    margin-top: 1.5rem;
    color: #2037b6;
    word-wrap: break-word;
   text-align:center;
   
  }

  .networkDesc {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    color: #2037b6;

    @media (max-width: 767px) {
      text-align: justify;
      padding: 0 1rem;
    }
  }
`;

export const MainHeading = styled.h3`
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  line-height: 48.76px;
  color: #2037b6;
`;
// AgeNetworkInfoWrapper

export const CryptoInfo = styled.div`
  background: #2037b6;
  color: white;
  padding: 4.5rem 0;

  .title {
    font-size: 56px;
    font-weight: 700;
    line-height: 68.26px;
    text-align: center;
    margin: 0;
    padding: 0;
  }
  .desc {
    font-size: 20px;
    font-weight: 300;
    line-height: 24.38px;
    text-align: center;

    margin: 0;
    padding: 0;
    margin-top: 1rem;
  }
`;

export const AgeWorkWrapper = styled.div`
  background: #f8f2e2;
  padding: 6.5rem 3rem;
  .ageCardWrapper {
    display: flex;
    justify-content: center;
    gap: 1.3rem;
    flex-wrap: wrap;
    margin-top: 1rem;
  }
  @media (max-width: 767px) {
    padding: 5rem 1rem;
  }
`;
export const AGECard = styled.div`
  border: 1px solid #d3d3d3;
  background: #fdfbef;
  width: 562px;
  height: 212px;
  gap: 0px;
  // border: 1px 0px 0px 0px;

  padding: 1.4rem;
  .title {
    color: #2037b6;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.5px;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 0.2rem;
    margin-top: 0.7rem;
  }
  ul {
    font-size: 16px;
    font-weight: 400;
    color: #2037b6;
    padding: 0.2rem 1.7rem;
    line-height: 19.5px;

    li {
      margin-top: 0.5rem;
    }
  }

  @media (max-width: 767px) {
    height: 100%;
  }
`;

export const AgeCalculatorWrapper = styled.div`
  .parentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    // padding-top:3rem;
  }

  background: #fdfbef;
  padding-top: 6rem;

  // .calculatorTab {
  //   display: flex;
  //   justify-content: space-between;
  //   flex-wrap:wrap;
  // }
  // .leftRow {
  //   width: 50%;
  // }
  // .rightRow {
  //   width: 50%;
  // }
  // .underline2 {
  //   display: block;
  //   margin: auto;
  //   padding-top: 0.7rem;
  // }
  // .interactorCalc {
  //   padding: 0;
  //   margin: 0;
  //   text-align: center;
  //   font-family: Montserrat;
  //   font-size: 24px;
  //   font-weight: 700;
  //   line-height: 29.26px;
  //   text-align: center;
  //   color: #2037b6;
  //   cursor:pointer;
  // }

  // .publishableCalc {
  //   padding: 0;
  //   margin: 0;
  //   font-family: Montserrat;
  //   font-size: 24px;
  //   font-weight: 700;
  //   line-height: 29.26px;
  //   text-align: center;
  //   color: #2037b6;
  //   cursor:pointer;

  // }

  // @media (max-width: 767px) {
  //   .calculatorTab
  //   {

  //   }
  //   .leftRow
  //   {
  //     width:100%;
  //     margin-bottom:1rem;
  //   }
  //   .rightRow
  //   {
  //     width:100%;
  //   }
  // }
  // // calculatorTab
  // .calculatorWrap {
  //   display: flex;
  //   justify-content: center;
  //   gap: 0rem;
  //   flex-wrap: wrap;
  //   // padding-top: 2rem;
  //   flex-direction: row;
  //   margin-top: 3rem;

  //   .title {
  //     color: #2037b6;
  //     font-size: 24px;
  //     font-weight: 700;
  //     line-height: 29.26px;
  //     text-align: center;
  //     padding: 0;
  //     margin: 0;
  //   }
  //   .underline {
  //     display: block;
  //     margin: auto;
  //     padding-top: 0.7rem;
  //     padding-left: 12rem;
  //   }

  //   .underline2 {
  //     display: block;
  //     margin: auto;
  //     padding-top: 0.7rem;
  //   }
  //   .desc {
  //     font-family: Montserrat;
  //     font-size: 14px;
  //     font-weight: 400;
  //     line-height: 19.5px;
  //     color: #2037b6;

  //     @media (max-width: 767px) {
  //       padding: 0rem 1rem;
  //     }
  //   }
  //   .cardLeft {
  //     width: 50%;

  //     .title {
  //       margin-left: 12rem;
  //       cursor: pointer;
  //     }

  //     .desc {
  //       display: flex;
  //       justify-content: center;
  //       text-align: justify;
  //       margin-left: 5rem;
  //       width: 34rem;
  //       font-family: Montserrat;
  //       font-size: 16px;
  //       font-weight: 400;
  //       line-height: 19.5px;
  //        margin-top:1rem;
  //     }

  //     @media (max-width: 1400px) {
  //       .desc {
  //         margin-left: 0rem;
  //       }
  //     }
  //     @media (max-width: 1200px) {
  //       .desc {
  //         margin-left: -2rem;
  //         width:100%;
  //       }
  //     }
  //     @media (max-width: 990px) {
  //       width: 100%;
  //       .title {
  //         margin-left: 0rem;
  //       }
  //       .desc {
  //         margin-left: 0rem;
  //         text-align: justify;
  //         margin-bottom: 1rem;
  //       }
  //       .underline {
  //         padding-left: 0rem;
  //       }
  //     }
  //     @media (max-width: 767px) {
  //       margin: 0 2rem;
  //     }
  //   }
  //   .cardRight {
  //     width: 50%;
  //     .title {
  //       cursor: pointer;
  //       margin: 0;
  //       padding: 0;
  //     }
  //     .AgeCalculatorCard {
  //       display: flex;
  //       justify-content: end;
  //     }
  //     @media (max-width: 990px) {
  //       width: 100%;
  //     }
  //     @media (max-width: 767px) {
  //       width: 95%;
  //     }
  //   }
  // }
`;
export const AgeCalculatorCard = styled.div`
  border: 1px solid #dddddd;
  width: 100%;
  padding: 0 2.2rem;
  border-radius: 8px;
  // margin-top: 2.5rem;
  width: 632px;
  height: 356px;

  .totalPointsDiv {
    margin-top: 2.3rem;
    border-radius: 4px;
    background: #e9e9e9;
    height: 39px;
    p {
      font-size: 16px;
      font-weight: 700;
      line-height: 19.5px;
      text-align: center;
      color: #333333;
      padding: 0.65rem;
    }
  }

  .mainText {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 400;
    line-height: 24.38px;
    text-align: center;
    color: #2037b6;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }
  .calculatorSliderWrap {
    display: flex;
    margin-top: 1rem;

    & > div:nth-child(1) {
      flex: 30%;
    }

    & > div:nth-child(2) {
      flex: 40%;
    }

    .title {
      color: #2037b6;
      font-size: 20px;
      font-weight: 700;
      line-height: 24.38px;
      text-align: left;
      padding: 0;
      margin: 0;
    }
    .desc {
      color: #2037b6;
      font-size: 10px;
      font-weight: 700;
      line-height: 12.19px;
      text-align: left;
      padding: 0;
      margin: 0;
    }
    .points {
      padding: 0;
      margin: 0;
      color: #2037b6;

      font-size: 10px;
      font-weight: 400;
      line-height: 12.19px;
      text-align: right;
    }
  }
  @media (max-width: 767px) {
    padding: 0 0.4rem;
  }
`;

export const InvestorWrapper = styled.div`
  background: #fdfbef;
  padding: 10rem 0;
  .artPartner {
    margin-top: 6.4rem;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
    text-align: center;
  }
  .pressFeatures {
    margin-top: 6.4rem;
    font-size: 16px;
  }
  p {
    color: #0000ab;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
    text-align: center;
    margin-top: 1rem;
  }
  .mainInvestorWrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 60px;
  }
  .mainInvestorWrap2 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 80px;
  }
`;
export const CustomSlider = styled(ReactSlider)`
  width: 20rem;
  height: 1px;
  margin-top: 1rem;

  .track {
    background: #2037b6 !important; /* Force background color with !important */
    height: 1px; /* Ensure track is visible by setting a height */
    top: 50%;
    position: absolute;

    transform: translateY(-50%);
  }

  .thumb {
    height: 20px;
    line-height: 20px;
    width: 20px;
    text-align: center;
    background-color: #bd4088; /* Pink color for the thumb */
    border-radius: 50%; /* Round shape for the thumb */
    cursor: grab;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;
