import styled from "styled-components";

export const MainSection = styled.section`
  padding-top:0.5rem;
  .logoText {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.94px;
    text-align: center;
    color: white;
   
  }
  .nav-link.active
  
{
  color:white !important;
}

.nav-link:hover
  
{
  color:white !important;
}

  a {
    color: #fff;
     font-size: 18px;
    font-style: normal;
    font-weight: 200;
    line-height: 60px; /* 400% */
    text-decoration: none;
    padding: 0rem 0.6rem;
    @media (max-width: 990px) {
      text-align: center;
      line-height: normal;
    }

    &:not(:first-child) {
      margin-left: 1rem;

      @media (max-width: 990px) {
        margin-left: 0rem;
      }
    }
  }

  .active {
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
  }

  .nav-link {
    font-size: 18px;
    font-weight: 700;
    line-height: 21.94px;
    text-align: center;

    @media (max-width: 990px) {
      text-align: center;
      line-height: normal;
      margin-top:1rem;
    }

    &:not(:first-child) {
      // margin-left: 1rem;

      @media (max-width: 990px) {
        margin-left: 0rem;
      }
    }
  }

  .navbar-toggler {
    background-color: #fff;
  }

  .menu-link {
    // margin-right: 5rem;

    @media (max-width: 990px) {
      margin-right: 0rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const ConnectBtn = styled.button`
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  width: 164px;
  border: none;
  height: 43px;
  background-color: #bd4088;

  margin-left:1rem ;

  @media (max-width: 990px) {
    margin-top: 1rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &:hover {
    background: #f387c5;
    color: #fff;
  }
`;
