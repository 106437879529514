import React from "react";
import { AgeNetworkInfoWrapper, CryptoInfo, MainHeading } from "./elements";

const AgeNetworkInfo = () => {
  return (
    <>
      <AgeNetworkInfoWrapper>
        <MainHeading> What is ArtGee Network?</MainHeading>
        <div className={"text_dis"}>
          <p>
            ArtGee Network is a decentralized network tailored for crypto art,
            aiming to boost the visibility and liquidity of the entire crypto
            art ecosystem
          </p>
          <p>by fostering the development of the crypto art economy.</p>
        </div>
        <div className={"text_dis"}>
          <p>
            To date, there has not been a fair and solid consensus-based
            decentralized token economy in the global crypto art market. As the
            first Web3
          </p>
          <p>
            Art-Fi social information aggregator within the Ethereum ecosystem,
            ArtGee Network pioneers the 'curate to earn' model, where users can
          </p>
          <p>
            earn points and exchange them for tokens as rewards for curating and
            promoting quality crypto art events and content.
          </p>
        </div>
      </AgeNetworkInfoWrapper>

      <CryptoInfo>
        <p className="title"> NEW CRYPTO ART $AGE</p>
        <p className="desc"> AGE Coin: ArtFi Revolution on Twitter/X</p>
      </CryptoInfo>
    </>
  );
};

export default AgeNetworkInfo;
