import FooterComp from "components/common/footer";
import AgeNetworkInfo from "./ageNetworkInfo";
import AgeWork from "./ageWork";
import HeroSection from "./heroSection";
import AgeCalculator from "./ageCalculator";

const HomeCom = () => {
  return (
    <>
      <HeroSection />
      <AgeNetworkInfo/>
      <AgeWork/>
      <AgeCalculator/>
      <FooterComp/>
    </>
  );
};

export default HomeCom;
