export const Images = {
  web3: {
    metamask: require("./web3/metamask.png"),
    wallet: require("./web3/wallet.png"),
  },
  login: {
    bg: require("./images/bg-img.png"),
  },
};

export { default as homebg } from "./images/homebg.png";
export { default as logo } from "./images/logo.png";
export { default as logo2 } from "./images/logo2.png";
export { default as nodata } from "./images/nodata.svg";


export { default as rightArrow } from "./images/arrow.png";
export { default as swapicon } from "./images/swapicon.png";
export { default as tokenicon } from "./images/token.png";
export { default as btcicon } from "./images/btc.png";
export { default as ethicon } from "./images/eth.png";
export { default as ltcicon } from "./images/ltc.png";
export { default as ustdcicon } from "./images/usdt.png";

export { default as artwork } from "./images/artwork.png";
export { default as tokenex } from "./images/tokenex.png";
export { default as search } from "./images/search.png";
export { default as underline } from "./images/underline.png";



export { default as l05g } from "./images/partner/l05g.png";
export { default as artasiapacific } from "./images/partner/artasiapacific.png";
export { default as businessinsider } from "./images/partner/businessinsider.png";
export { default as cointelegraph } from "./images/partner/cointelegraph.png";
export { default as conflux } from "./images/partner/conflux.png";
export { default as digitalresounance } from "./images/partner/digitalresounance.png";
export { default as iimo } from "./images/partner/iimo.png";
export { default as nifty } from "./images/partner/nifty.png";
export { default as satoshi } from "./images/partner/satoshi.png";
export { default as superrare } from "./images/partner/superrare.png";
export { default as vega } from "./images/partner/vega.png";
export { default as withoutname } from "./images/partner/withoutname.png";



export { default as launcher } from "./images/launch.png";
export { default as divImg } from "./images/divimg.png";

export { default as avax } from "./images/avalanche-avax.png";
export { default as bnb } from "./images/bnb.png";
export { default as cardona } from "./images/cardano-ada.png";
export { default as degecoin } from "./images/dogecoin-doge.png";
export { default as pancake } from "./images/pancakeswap-cake.png";
export { default as solana } from "./images/solana-sol.png";
export { default as usdc } from "./images/usd-coin-usdc.png";
 




