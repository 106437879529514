import styled from "styled-components";

export const Text = styled.h5`
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  
`;

export const FooterWrapper = styled.div`

 .email
 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  
 }

  .mainWrapper
  {
    display:flex;
     flex-wrap:wrap;
    width:100%;
    >div 
    {
      flex: 1 1 100%;
    }    
    & > div:nth-child(1) {
      flex: 50%;
    }

    & > div:nth-child(2) {
      flex: 25%;
      
      ul {
        li {
          margin-top:0.6rem;
          a {
            text-decoration: none;
            color:white;
            font-family: Montserrat;
            // font-size: 16px;
            font-weight: 400;
            line-height: 19.5px;
        }
        }
      }
    }
    & > div:nth-child(3) {
      flex: 25%;
    }
    
    @media (max-width: 567px) {
      padding: 0 0.4rem;
    }
  }
    
  }
  
  background: #2037b6;

  padding: 4rem 3rem;
  height:400px;
  .contact {
    font-size: 1rem;  
    // padding-left: 8rem;
    color: white;
    font-family: Montserrat;
  
   
  }
  @media (max-width: 767px) {
    height:100%;
        
      }
`;
