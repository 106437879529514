import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./pages";
import GlobalStyle from "globalStyles";
import FooterComp from "components/common/footer";
function App() {
  return (
    <Router>
      <GlobalStyle />
      <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <div style={{ flex: 1 }}>
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        </div>
       </div>
    </Router>
  );
}

export default App;
