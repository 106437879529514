const environment = {
  BACKEND_BASE_URL: "http://localhost:8080",
  RPC: {
    1: "https://mainnet.infura.io/v3/db1427c3f86f4a95a2dfde7849404077",
    11155111:
      "https://eth-sepolia.g.alchemy.com/v2/I6Atoqbgarld3nqt4cSNkMzt1Dc_9BLb",
  },
  IDO_ADDRESS: {
    1: "0xb57AEdb7f7A799020fD449e5ac1CEd9b05EBd9e1",
    11155111: "0xB67aa50bdb1f349b92641b8CCDcc5B2E9ff3060E",
  },
  AGE_TOKEN: {
    1: "0x0cc8D1C358C007cac55Fa0Bb3c750E723538f659",
    11155111: "0xfbF91f18EdCd2Ed95fAfE44A93AF1cb583cb2249",
  },
  USDT: {
    1: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    11155111: "0x5e30bEf46783960d5d5b48A72C93Bd4d15e3A4Da",
  },
  USDT_DECIMALS: 6,
  AGE_DECIMALS: 18,
  USDT_UNIT: "mwei",
  AGE_UNIT: "ether",
  DEFAULT_CHAIN: 1,
  USD_PRICE: "100000",
  PRESALE_AMOUNT: 10000000,
};
export default environment;
