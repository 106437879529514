import React, { useState } from "react";
import {
  AgeCalculatorCard,
  AgeCalculatorWrapper,
  CustomSlider,
  InvestorWrapper,
  MainHeading,
} from "./elements";
import {
  artasiapacific,
  businessinsider,
  cointelegraph,
  conflux,
  digitalresounance,
  iimo,
  l05g,
  nifty,
  satoshi,
  superrare,
  underline,
  vega,
  withoutname,
} from "assets";
import { Container } from "react-bootstrap";
import CalculaterSelector from "components/CalculaterSelector/CalculaterSelector";

const AgeCalculator = () => {
  const [viewspoint, setviewspoint] = useState(0);
  const [likespoint, setlikespoint] = useState(50);
  const [repliespoint, setrepliespoint] = useState(100);
  const [retweetspoint, setretweetspoint] = useState(200);
  const [views] = useState(0);
  const [likes, setLikes] = useState(0);
  const [replies, setReplies] = useState(0);
  const [retweets, setRetweets] = useState(0);

  const calculateTotalPoints = () => {
    return (
      views * viewspoint +
      likes * likespoint +
      replies * repliespoint +
      retweets * retweetspoint
    );
  };

  const handleChange = (event, newValue) => {
    console.log("event", event);
    setLikes(newValue);
  };

  const [isPublisher, setIsPublisher] = useState(true);

  return (
    <div>
      <AgeCalculatorWrapper>
        <MainHeading>$AGE Calculater</MainHeading>
        {/* <Container>
          <div>
            <div className="calculatorTab">
              <div className="leftRow">
                <p
                  className="publishableCalc"
                  onClick={() => {
                    setIsPublisher(true);
                  }}
                >
                  Publisher Calculator
                </p>
                {isPublisher && (
                  <img className="underline2" src={underline} alt="" />
                )}
              </div>
              <div className="rightRow">
                <p
                  className="interactorCalc"
                  onClick={() => {
                    setIsPublisher(false);
                  }}
                >
                  Interactor Calculator
                </p>
                {!isPublisher && (
                  <img className="underline2" src={underline} alt="" />
                )}
              </div>
            </div>
          </div>
          <div className="calculatorWrap">
            <div className="cardLeft">
              <p className={"desc"}>
                {isPublisher
                  ? "As an art publisher, your role is to share and display crypto art. Publish your artworks on social media with the #age tag earn points. For detailed information on how points are  accumulated, please refer to the calculator on the right.Your published art tweets must explicitly contain the #age tag to enable interactors to find and engage with them."
                  : "The task of an art interactor is to engage with the works of art publishers on social media platforms. You can earn points by liking, commenting, or retweeting artworks that include the #age tag. For detailed information on how points are earned, please refer to the calculator on the right. Your interactions will directly support the artists and allow you to accumulate points."}
              </p>
            </div>
            <div className="cardRight">
              <div className="AgeCalculatorCard">
                <AgeCalculatorCard>
                  <p className="text-center mainText">Publisher Calculator</p>
                  <div className="calculatorSliderWrap">
                    <div className="mt-2">
                      <h4 className="title mb-2">Likes</h4>
                      <p className="desc">1 Like = {likespoint} points</p>
                    </div>
                    <div>
                      <p className="points mb-1">{likes} Likes</p>
                      <p className="points"> {likes * likespoint} Points</p>

                      <CustomSlider
                        className="slider"
                        thumbClassName="thumb"
                        trackClassName="track"
                        value={likes}
                        max={5000}
                        onChange={(newValue) => setLikes(newValue)}
                      />
                    </div>
                  </div>

                  <div className="calculatorSliderWrap">
                    <div className="mt-2">
                      <h4 className="title mb-2">Replies</h4>
                      <p className="desc">1 Reply = {repliespoint} points</p>
                    </div>
                    <div>
                      <p className="points mb-1">{replies} Replies </p>
                      <p className="points">{replies * repliespoint} Points</p>
                      <CustomSlider
                        className="slider"
                        thumbClassName="thumb"
                        trackClassName="track"
                        value={replies}
                        max={5000}
                        onChange={(newValue) => setReplies(newValue)}
                      />
                    </div>
                  </div>

                  <div className="calculatorSliderWrap">
                    <div className="mt-2">
                      <h4 className="title mb-2">Retweets</h4>
                      <p className="desc">1 retweet = {retweetspoint} points</p>
                    </div>
                    <div>
                      <p className="points mb-1">{retweets} Retweets</p>
                      <p className="points">
                        {retweets * retweetspoint} Points
                      </p>
                      <CustomSlider
                        className="slider"
                        thumbClassName="thumb"
                        trackClassName="track"
                        value={retweets}
                        max={5000}
                        onChange={(newValue) => setRetweets(newValue)}
                      />
                    </div>
                  </div>

                  <div className="totalPointsDiv">
                    <p>Total Points: {calculateTotalPoints()}</p>
                  </div>
                </AgeCalculatorCard>
              </div>
            </div>
          </div>
        </Container> */}
        <div className="parentContainer">
        <CalculaterSelector
          isPublisher={isPublisher}
          setIsPublisher={setIsPublisher}
        />
        </div>
        
      </AgeCalculatorWrapper>
      <InvestorWrapper>
        <p>Investors</p>
        <div className="">
          <div className="mainInvestorWrap my-5">
            <img width={130} height={33} src={digitalresounance} alt="" />
            <img width={90} height={45} src={vega} alt="" />
            <img width={125} src={satoshi} alt="" />
          </div>

          <div className="mainInvestorWrap  my-5">
            <img width={100} src={conflux} alt="" />
            <img width={140} src={iimo} alt="" />
            <img width={90} src={l05g} alt="" />
          </div>
        </div>
        <p className=" artPartner ">Art Partners</p>

        <div className="mainInvestorWrap2 my-4">
          <img width={100} height={40} src={superrare} alt="" />
          <img width={100} src={nifty} alt="" />
          <img width={90} src={withoutname} alt="" />
        </div>

        <p className="pressFeatures">Press Features</p>

        <div className="mainInvestorWrap2 my-4">
          <img width={90} height={45} src={businessinsider} alt="" />
          <img width={140} src={cointelegraph} alt="" />
          <img width={75} src={artasiapacific} alt="" />
        </div>
      </InvestorWrapper>
    </div>
  );
};

export default AgeCalculator;
