import React, { useState } from "react";
import { ConnectBtn, MainSection } from "./elements";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { MainContainer } from "../mainStyle/mainStyle";
import { Image } from "react-bootstrap";
import { logo } from "assets";
import { CommonUtility } from "utility/common";
import { useNavigate } from "react-router-dom";
import {
  createWeb3Modal,
  defaultConfig,
  useDisconnect,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { Link } from "react-router-dom";
const NavbarComp = () => {
  const navigate = useNavigate();
  const chains = [
    {
      chainId: 1,
      name: "Ethereum",
      currency: "ETH",
      explorerUrl: "https://etherscan.io",
      rpcUrl: "https://cloudflare-eth.com",
    },
    // {
    //   chainId: 11155111,
    //   name: "Sepolia",
    //   currency: "ETH",
    //   explorerUrl: "https://sepolia.etherscan.io",
    //   rpcUrl: "https://ethereum-sepolia-rpc.publicnode.com",
    // },
  ];

  const ethersConfig = defaultConfig({
    metadata: {
      name: "Web3Modal",
      description: "Web3Modal Laboratory",
      url: "https://web3modal.com",
      icons: ["https://avatars.githubusercontent.com/u/37784886"],
    },
    defaultChainId: 1,
    rpcUrl: "https://cloudflare-eth.com",
  });

  const projectId = "d54225f2b745b8a5ade8db259d224f88";

  createWeb3Modal({
    ethersConfig,
    chains,
    projectId,
    enableAnalytics: true,
    themeMode: "light",
    themeVariables: {
      "--w3m-color-mix": "#00DCFF",
      "--w3m-color-mix-strength": 20,
    },
  });
  const modal = useWeb3Modal();

  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { disconnect } = useDisconnect();
  const handleModelOpen = () => {
    modal.open();
  };

  return (
    <MainSection>
      <Navbar expand="lg">
        <MainContainer>
          <Navbar.Brand>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "0.5rem",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/");
              }}
            >
              <Image
                src={logo}
                width={25}
                height={20}
                style={{ cursor: "pointer" }}
              />
              <p className="logoText">AGE Coin</p>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Link to="/">
                <Nav.Link href="/">Home</Nav.Link>
              </Link>
              <Nav.Link href="https://docsend.com/view/ygs7sw7mgtyn6t6i" target="_blank">About</Nav.Link>
            </Nav>
            {isConnected ? (
              <ConnectBtn className="wallet">
                {" "}
                {CommonUtility.addressConvertor(address)}
              </ConnectBtn>
            ) : (
              <ConnectBtn onClick={handleModelOpen}>Connect Wallet</ConnectBtn>
            )}
          </Navbar.Collapse>
        </MainContainer>
      </Navbar>
    </MainSection>
  );
};

export default NavbarComp;
