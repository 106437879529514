import { MainCol, MainContainer, MainRow, ToastMessage } from "../common";
import NavbarComp from "components/common/navbar";
import {
  CardDiv,
  CoinWrapper,
  HeroSectionWrapper,
  MainSection,
  Rectangle,
  Title,
} from "./elements";
import { launcher, logo2, ustdcicon, ethicon } from "assets";
import { styled } from "@mui/system";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";

import Loading from "components/common/loaders/loading";
import { CommonUtility } from "utility/common";
import { swapAbi } from "utility/abis/swapAbi";
import environment from "environment";
import Web3 from "web3";
import { erc20Abi } from "utility/abis/erc20";

const CustomTextField = styled(TextField)({
  "& .MuiInputLabel-root": {
    color: "#fff",
    fontSize: "18px",
    fontFamily: "Montserrat",
    fontWeight: 600,
    "&:hover": {
      color: "#fff",
    },
  },
  "& label.Mui-focused": {
    color: "#ffffff", // Change label color when input is focused
  },
  "&:hover label": {
    color: "#ffffff", // Change label color when input is hovered
  },
  "& .MuiOutlinedInput-root": {
    width: "100%",
    borderColor: "#fff",
    boxShadow: "none",
    borderRadius: 0,

    // "&:hover": {
    //   "& fieldset": {
    //     borderColor: "#37404a",
    //   },
    //   "& .MuiInputLabel-root": {
    //     color: "#fff",
    //   },
    // },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#ffffff !important",
  },
  "& .MuiInputBase-input": {
    color: "#fff",
  },
  "& .MuiInputBase-root": {
    // fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "1.14rem",
    lineHeight: "1.4375em",
  },
});

const HeroSection = () => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const [sendValue, setSendValue] = useState(null);
  const [priceInEth, setPriceInEth] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    // if (walletProvider && chainId == environment.DEFAULT_CHAIN) {
    getEthPriceOfToken();
    //}
  }, []);

  const getEthPriceOfToken = async () => {
    try {
      const web3 = new Web3(environment.RPC[environment.DEFAULT_CHAIN]);
      const contract = CommonUtility.contract(
        web3,
        swapAbi,
        environment.IDO_ADDRESS[environment.DEFAULT_CHAIN]
      );
      const priceInWei = await contract.methods
        .tokenPriceInEth("1000000000000000000")
        .call();
      console.log("priceInWei", priceInWei);
      const price = web3.utils.fromWei(priceInWei, environment.AGE_UNIT);
      setPriceInEth(price);
    } catch (error) {
      console.log("getEthPriceOfToken error", error);
    }
  };
  const buyWithUsd = async () => {
    try {
      setIsLoading(true);
      if (!isConnected) throw "Please connect wallet";
      if (Number(sendValue) < 1) throw "Please enter sufficient amount";
      const currentTime = Date.now() / 1000;
      const web3 = new Web3(walletProvider);
      const contract = CommonUtility.contract(
        web3,
        swapAbi,
        environment.IDO_ADDRESS[environment.DEFAULT_CHAIN]
      );
      const presaleStartTime = await contract.methods.presaleStartTime().call();
      const presaleEndTime = await contract.methods.presaleEndTime().call();
      console.log(
        "presaleStartTime",
        presaleStartTime,
        "presaleEndTime",
        presaleEndTime
      );
      const soldTokensInWei = await contract.methods.tokensSold().call();
      const soldTokens = web3.utils.fromWei(
        String(soldTokensInWei),
        environment.AGE_UNIT
      );
      console.log("SOLD tokens", soldTokens);
      if (Number(soldTokens) + Number(sendValue) > environment.PRESALE_AMOUNT)
        throw "All tokens sold";
      if (currentTime < Number(presaleStartTime)) throw " Sale Not started yet";
      if (currentTime > Number(presaleEndTime)) throw " Sale Ended";
      const tokenContract = CommonUtility.contract(
        web3,
        erc20Abi,
        environment.USDT[environment.DEFAULT_CHAIN]
      );
      const ageContract = CommonUtility.contract(
        web3,
        erc20Abi,
        environment.AGE_TOKEN[environment.DEFAULT_CHAIN]
      );
      const contractBalance = await ageContract.methods
        .balanceOf(environment.IDO_ADDRESS[environment.DEFAULT_CHAIN])
        .call();
      console.log(
        "VALUES",
        Number(web3.utils.fromWei(contractBalance, environment.AGE_UNIT)),
        Number(sendValue)
      );
      if (
        Number(web3.utils.fromWei(contractBalance, environment.AGE_UNIT)) <
        Number(sendValue)
      )
        throw "Contract do not have enough tokens";

      const userBalance = await tokenContract.methods.balanceOf(address).call();
      const amountToBePaid = web3.utils.toWei(
        String(
          Number(
            web3.utils.fromWei(
              String(environment.USD_PRICE),
              environment.USDT_UNIT
            )
          ) * Number(sendValue)
        ),
        environment.USDT_UNIT
      );
      console.log("AMOUNT to be Paid", amountToBePaid);
      if (Number(userBalance) < Number(amountToBePaid))
        throw "Not enough Usdt Balance";
      const allowance = await tokenContract.methods
        .allowance(address, environment.IDO_ADDRESS[environment.DEFAULT_CHAIN])
        .call();
      if (Number(allowance) < Number(amountToBePaid)) {
        const totalSupply = await tokenContract.methods.totalSupply().call();
        const approval = await tokenContract.methods
          .approve(
            environment.IDO_ADDRESS[environment.DEFAULT_CHAIN],
            totalSupply
          )
          .send({ from: address });
        if (approval && approval.code == 4001)
          throw "User denied the transaction";
        if (!approval.status) throw "Transaction Failed";
      }
      const txn = await contract.methods
        .buyTokensInUSDT(
          web3.utils.toWei(String(sendValue), environment.AGE_UNIT)
        )
        .send({ from: address });
      if (txn && txn.code == 4001) throw "User denied the transaction";
      if (!txn.status) throw "Transaction Failed";
      if (txn.status) {
        setIsLoading(false);
        ToastMessage("Success!", "Transaction successful", "success");
      }
    } catch (error) {
      console.log("buyWithUsd error", error);
      setIsLoading(false);
      if (error?.message) {
        ToastMessage("Error", error?.message, "error");
      } else {
        ToastMessage("Error", error, "error");
      }
    }
  };

  const buyWithEth = async () => {
    try {
      setIsLoading(true);
      if (!isConnected) throw "Please connect wallet";
      if (Number(sendValue) < 1) throw "Please enter sufficient amount";
      const currentTime = Date.now() / 1000;
      const web3 = new Web3(walletProvider);
      const contract = CommonUtility.contract(
        web3,
        swapAbi,
        environment.IDO_ADDRESS[environment.DEFAULT_CHAIN]
      );
      const presaleStartTime = await contract.methods.presaleStartTime().call();
      const presaleEndTime = await contract.methods.presaleEndTime().call();
      console.log(
        "presaleStartTime",
        presaleStartTime,
        "presaleEndTime",
        presaleEndTime
      );
      const soldTokensInWei = await contract.methods.tokensSold().call();
      const soldTokens = web3.utils.fromWei(
        String(soldTokensInWei),
        environment.AGE_UNIT
      );
      console.log("SOLD tokens", soldTokens);
      if (Number(soldTokens) + Number(sendValue) > environment.PRESALE_AMOUNT)
        throw "All tokens sold";
      if (currentTime < Number(presaleStartTime)) throw " Sale Not started yet";
      if (currentTime > Number(presaleEndTime)) throw " Sale Ended";
      const ageContract = CommonUtility.contract(
        web3,
        erc20Abi,
        environment.AGE_TOKEN[environment.DEFAULT_CHAIN]
      );
      const contractBalance = await ageContract.methods
        .balanceOf(environment.IDO_ADDRESS[environment.DEFAULT_CHAIN])
        .call();
      console.log(
        "VALUES",
        Number(web3.utils.fromWei(contractBalance, environment.AGE_UNIT)),
        Number(sendValue)
      );
      if (
        Number(web3.utils.fromWei(contractBalance, environment.AGE_UNIT)) <
        Number(sendValue)
      )
        throw "Contract do not have enough tokens";
      const userBalance = await web3.eth.getBalance(address);
      const amountToBePaid = await contract.methods
        .tokenPriceInEth(
          web3.utils.toWei(String(sendValue), environment.AGE_UNIT)
        )
        .call();
      if (Number(userBalance) < Number(amountToBePaid))
        throw "Not enough Eth Balance";

      const txn = await contract.methods
        .buyTokensInEth(
          web3.utils.toWei(String(sendValue), environment.AGE_UNIT)
        )
        .send({ from: address, value: amountToBePaid });
      if (txn && txn.code == 4001) throw "User denied the transaction";
      if (!txn.status) throw "Transaction Failed";
      if (txn.status) {
        setIsLoading(false);
        ToastMessage("Success!", "Transaction successful", "success");
      }
    } catch (error) {
      console.log("buyWithEth error", error);
      setIsLoading(false);
      if (error?.message) {
        ToastMessage("Error", error?.message, "error");
      } else {
        ToastMessage("Error", error, "error");
      }
    }
  };
  return (
    <HeroSectionWrapper>
      <NavbarComp />
      {isLoading && <Loading content={"Processing"} />}
      <MainSection>
        <MainContainer>
          <MainRow className="main-row">
            <MainCol lg={6}>
              <div className="launchDiv my-3">
                <img src={launcher} width={86} height={86} alt="" />
              </div>
              <p className="textDescription text-lg-start text-center">
                Secure Your Share:
              </p>
              <Title>
                Join the{" "}
                <CoinWrapper>
                  <span> AGE Coin</span>
                  <Rectangle />
                </CoinWrapper>{" "}
              </Title>

              <p className="idoText">IDO Now.</p>
            </MainCol>
            <MainCol lg={6}>
              <div className="main-card-div">
                <CardDiv>
                  <div className="cardWrapper">
                    <div className="logoDiv">
                      <img src={logo2} width={90} height={90} alt="" />
                    </div>

                    <div className="" style={{ marginTop: "2rem" }}>
                      <div>
                        <div style={{ width: "100%" }}>
                          <CustomTextField
                            id="outlined-required"
                            label="Amount of AGE to purchase:"
                            type="number"
                            variant="outlined"
                            style={{ width: "100%" }}
                            InputLabelProps={{
                              shrink: true,
                              className: "",
                            }}
                            sx={{
                              "& fieldset": { borderColor: "#37404a" },
                            }}
                            inputProps={{
                              min: 0, // Set minimum value to 0
                          
                            }}
                            outlined={false}
                             onChange={(e) => setSendValue(e.target.value)}
                            value={sendValue}
                          />
                        </div>
                      </div>
                      <div className="d-flex my-2 justify-content-between">
                        <p className="estimatedPara">Estimated USDT:</p>
                        <p className="estimatedValue">
                          {(Number(
                            CommonUtility.convertFromWei(
                              environment.USD_PRICE,
                              environment.USDT_DECIMALS
                            )
                          ) * Number(sendValue))?.toFixed(1)}{" "}
                          ${" "}
                        </p>
                      </div>
                      {/* <p className="estimatedValue mt-4 text-center">
                        1 AGE ={" "}
                        {CommonUtility.convertFromWei(
                          environment.USD_PRICE,
                          environment.USDT_DECIMALS
                        )}{" "}
                        USDT
                      </p> */}
                      <p className="estimatedValue mt-4 text-center">
                        1 AGE ={Number(priceInEth)?.toFixed(5)} ETH
                      </p>

                      <div className="d-flex justify-content-center flex-column gap-2">
                        {/* <button
                          className="purchaseBtn mb-2 mt-4"
                          onClick={() => buyWithUsd()}
                        >
                          Purchase AGE with USDT{" "}
                          <img src={ustdcicon} width={20} alt="" />
                        </button> */}
                        <button
                          className="purchaseBtn"
                          onClick={() => buyWithEth()}
                        >
                          Purchase AGE with ETH{" "}
                          <img src={ethicon} width={10} alt="" />
                        </button>
                      </div>
                    </div>
                  </div>
                </CardDiv>
              </div>
            </MainCol>
          </MainRow>
        </MainContainer>
      </MainSection>
    </HeroSectionWrapper>
  );
};

export default HeroSection;
